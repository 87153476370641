import style from './style.module.css'

import image52 from '../../assets/image52.png'
import image53 from '../../assets/image53.png'
import image54 from '../../assets/image54.png'
import image55 from '../../assets/image55.png'

export default function FolhletosPage(){
    return (
        <div>
            <h1 className={style.title}>Folhetos</h1>

            <p>Os materiais informativos como, por exemplos, folhetos, cartilhas têm sido utilizados para melhorar o conhecimento, a satisfação, a aderência ao tratamento,  o autocuidado, as ações preventivas, proporcionando o processo de ensino e aprendizagem por meio de interações entre o profissional e o paciente.</p>
            <p>Vários autores relatam que os folhetos são fonte de informação mais eficiente, fiável, econômica e prontamente disponível para o profissional da saúde servindo de complemento à informação na prática clínica para prevenir as lesões causadas pelo uso dos EPIs  e orientar a técnica correta de utilização dos EPIs. </p>

            <h2 className={style.subtitle}>Folheto para orientar profissionais da saúde na paramentação dos EPIs  </h2>

            <img className="img-fluid" src={image52} alt="Folheto" />

            <p>Nesse contexto, os folhetos são instrumentos ou objetos que podem servir como recursos que possibilitam o aprendizado de algo, estimulando e dirigindo o processo de ensino e aprendizagem. Podem ser entendidos como uma ferramenta fundamental de mediação, que se dá pelos instrumentos, da produção da cultura humana e sua relação com o mundo. O uso deste material didático perpassa assim questões institucionais, culturais, históricas, políticas e econômicas.</p>
            
            <h2 className={style.subtitle}>Folheto para orientar profissionais da saúde na paramentação dos EPIs  </h2>

            <img className="img-fluid" src={image53} alt="Folheto" />

            <h2 className={style.subtitle}>Folheto para orientar profissionais da saúde na desparamentação dos EPIs  </h2>

            <img className="img-fluid" src={image54} alt="Folheto" />

            <h2 className={style.subtitle}>Folheto para orientar profissionais da saúde na desparamentação dos EPIs   </h2>

            <img className="img-fluid mb-5" src={image55} alt="Folheto" />

        </div>
    )
}