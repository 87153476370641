import style from './style.module.css'

import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import image5 from '../../assets/image5.png'
import image5a from '../../assets/image5a.png'
import image6 from '../../assets/image6.png'
import image7 from '../../assets/image7.png'
import image8 from '../../assets/image8.png'
import image9 from '../../assets/image9.png'
import image10 from '../../assets/image10.png'
import image11 from '../../assets/image11.png'
import image12 from '../../assets/image12.png'
import image13 from '../../assets/image13.png'
import image14 from '../../assets/image14.png'
import image15 from '../../assets/image15.png'

export default function RecomendacoesPage(){
    return (
        <div>
            <h1 className={style.title}>Recomendação para o atendimento domiciliar na pandemia da COVID-19</h1>

            <p>A Atenção Domiciliar (AD) é um tipo de atendimento à atenção à saúde integrada às redes de atenção à saúde (RAS), caracterizada por um conjunto de ações de prevenção e tratamento de doenças, reabilitação, paliação e promoção à saúde, prestadas em domicílio, garantindo a continuidade da assistência.</p>
            <p>O cuidados domiciliares apresentam-se de diferentes formas nas RAS, seja por via de serviços próprios e equipes programadas para prestar cuidados, conforme as necessidades de cada indivíduo visitado pelos profissionais, seja por meio de atividades prestadas no domicílio como parte do processo de trabalho de outros pontos da rede de atenção, como serviços ligados à Atenção Primária à Saúde (APS), serviços de urgência, de reabilitação ou cuidados paliativos.</p>
            <p>A atuação dos profissionais da saúde que prestam assistência domiciliar ultrapassam os ambientes hospitalares. Muitos atuam nos lares dos pacientes; e, nesse contexto de saúde, os pacientes domiciliares geralmente são aqueles com quadros agudos, crônicos, reagudizados, em cuidados paliativos, com síndromes respiratórias, distúrbios neurológicos, hipertensos, diabéticos, estando mais suscetíveis ao vírus SARS-CoV-2.</p>
            <p>Os profissionais que prestam assistência domiciliar devem ter conhecimentos técnicos científicos que extrapolam aqueles aprendidos durante a formação do profissional, pois adentrar o domicílio e desenvolver as ações de cuidado exigem muito mais do que saber e agir. Isso porque o cuidado é realizado em um espaço de domínio do paciente e de sua família, e o profissional de saúde é um mero convidado.</p>
            <p>Em relação à COVID-19, a AD se apresenta como opção especialmente eficaz para três dos objetivos estratégicos do Sistema Unico (SUS) na resposta à pandemia:</p>

            <ul>
                <li>Interromper a transmissão de humano para humano ao manter pessoas em casa;</li>
                <li>Identificar e isolar pacientes infectados precocemente, cuidando deles com atendimento diferenciado;</li>
                <li>Disponibilizar leitos hospitalares via desospitalização ágil de pacientes estabilizados, tanto com COVID-19 quanto sem COVID-19, com a continuidade deste cuidado no domicílio.</li>
            </ul>

            <p>Outro fator importante que, durante a visita domiciliar, o profissional deve levar em conta é o uso de máscaras e afastamento social, quando em ambientes públicos. Deve seguir a orientação quanto às medidas de precaução na entrada do domicílio (considerando cuidados para a descontaminação de produtos comprados e das roupas), bem como o estabelecimento de áreas de deposição de roupas potencialmente contaminadas. 18, 19, 20 A Atenção Primária à Saúde como Atenção Domiciliar e o Serviço de Atenção Domiciliar (SAD) podem ser agentes importantes para detectar casos de COVID-19 e contactantes, para identificar a circulação do vírus, utilizando testes rápidos ou coleta de material para exames. As recomendações que os profissionais devem fornecer ao pacientes, familiares e cuidadores, durante o atendimento domiciliar em pacientes com COVID-19 são:</p>

            <h2 className={style.subtitle}>Isolamento do paciente</h2>

            <ul>
                <li>Manter o paciente em quarto individual bem ventilado ou em quarto privado, guardando uma distância de pelo menos um metro da pessoa doente, o qual deve ter sua circulação limitada ao máximo nos outros ambientes.</li>
                <li>Os ambientes compartilhados, como cozinha e banheiro, devem estar sempre bem ventilados.</li>
                <li>Sempre que o paciente sair do quarto, deverá estar usando máscara cirúrgica.</li>
                <li>Não receber visitas no período em que o paciente estiver em isolamento.</li>
                <li>Alguns itens devem ser separados para uso individual da pessoa doente, como: escovas de dente, talheres, pratos, utensílios de bebidas, toalhas ou roupas de cama.</li>
                <li>A higienização desses itens deve ser feita com água e sabão separadamente de outros utensílios a serem reutilizados.</li>
            </ul>

            <h2 className={style.subtitle}>Orientação para o cuidador</h2>

            <ul>
                <li>A escolha do cuidador deve limitar-se a uma pessoa preferencialmente saudável e sem fatores de risco (doenças crônicas, imunocomprometidos), sendo indesejável a troca de cuidadores, mesmo que familiares, sem a orientação adequada pela equipe do SAD.</li>
                <li>Sempre que o cuidador estiver no mesmo espaço ou manipulando o paciente, deve usar a máscara cirúrgica bem ajustada ao rosto. (Figuras 4 e 5)</li>
                <li>As máscaras não devem ser tocadas ou manuseadas durante o uso. Se a máscara ficar molhada ou suja com secreções, deverá ser trocada imediatamente.</li>
            </ul>


            <ol>
                <li>
                    <p>Higienização das mãos</p>
                    <img className="img-fluid" src={image3} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Segure a máscara pelo elástico</p>
                    <img className="img-fluid" src={image4} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Colocar a máscara sobre o rosto. Ajuste para ficar certinho no rosto sem vãos</p>
                    <img className="img-fluid" src={image5} alt="Higienizar as mãos" />
                    <img className="img-fluid" src={image5a} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Cubra o nariz inteiro e o queixo</p>
                    <img className="img-fluid" src={image6} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Quando for tirar a máscara, é de trás para frente, segurando sempre pelos elásticos</p>
                    <img className="img-fluid" src={image7} alt="Higienizar as mãos" />
                </li>
            </ol>

            <h2 className={style.subtitle}>Forma Incorreta de utilização da máscara</h2>

            <ul>
                <li>
                    <p>Não deixe o nariz descoberto</p>
                    <img className="img-fluid" src={image8} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Não deixe o queixo exposto</p>
                    <img className="img-fluid" src={image9} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Não use máscara larga com vãos nas laterais</p>
                    <img className="img-fluid" src={image10} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Não puxe a mascára para o queixo</p>
                    <img className="img-fluid" src={image11} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Não deixa a máscara livre na orelha </p>
                    <img className="img-fluid" src={image12} alt="Higienizar as mãos" />
                </li>
                <li>
                    <p>Não encoste na superfície da máscara ao colocá-la  ou tirá-la  </p>
                    <img className="img-fluid" src={image13} alt="Higienizar as mãos" />
                </li>
            </ul>

            <ul>
                <li>Devem-se utilizar luvas descartáveis ao efetuar cuidados orais ou respiratórios, quando manipular fezes, urina e resíduos.</li>
                <li>Após o contato e a realização dos cuidados, desprezar a máscara e luvas em lixeira com saco, o qual deve permanecer no quarto da pessoa doente até o momento do descarte com outros resíduos domésticos.</li>
                <li>Após o descarte, executar a higiene das mãos com água e sabonete ou álcool 70%.</li>
            </ul>

            <img className="img-fluid mb-3" src={image14} alt="Higienizar as mãos" />

            <h2 className={style.subtitle}>Orientação para a família</h2>

            <ul>
                <li>Higiene das mãos com água e sabonete; para secar as mãos utilizar, preferencialmente, toalhas de papel descartáveis. Caso toalhas de papel descartáveis não estejam disponíveis, usar toalhas de pano e trocar quando ficarem molhadas.</li>
                <li>Mãos aparentemente sujas demandam limpeza com água e sabão, e mãos não visivelmente sujas podem ser higienizadas com álcool 70%.</li>
                <li>As superfícies como mesas de cabeceira, quadros de cama e outros móveis do quarto e o banheiro, deverão ser desinfetadas diariamente pelo menos uma vez ao dia com desinfetante doméstico ou solução de água sanitária com a concentração do princípio de cloro ativo de 2 a 2,5%, diluir 25mL (meio copo de café de 50mL) em um litro de água.</li>
                <li>A pessoa que vai fazer a limpeza das roupas ou superfícies com fluidos corporais deverá utilizar máscara, luvas descartáveis e aventais de proteção de plástico, retirando imediatamente após a limpeza, lembrando de remover o avental antes das luvas.</li>
                <li>O distanciamento social deve ser estimulado, bem como o uso de máscaras baseados em boas evidências clínicas, portanto, seu uso deve ser incentivado quando as pessoas necessitarem sair de casa, por exemplo, para fazerem as compras.</li>
            </ul>

            <h2 className={style.subtitle}>Educação em saúde</h2>

            <ul>
                <li>Abordar a técnica correta da higienização das mãos (todos da casa), sendo realizada antes e após qualquer tipo de contato com o paciente, antes e depois do preparo dos alimentos, antes de comer e depois de usar o banheiro.</li>
                <li>Abordar etiqueta respiratória, cobrindo a boca e o nariz durante tosse e espirros, usando máscara cirúrgica, lenços de papel ou cotovelo flexionado, seguido de higiene das mãos.</li>
            </ul>

            <img className="img-fluid" src={image15} alt="Higienizar as mãos" />


        </div>
    )
}