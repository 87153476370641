import style from './style.module.css'

import image16 from '../../assets/image16.png'
import image17 from '../../assets/image17.png'
import image18 from '../../assets/image18.png'
import image19 from '../../assets/image19.png'
import image20 from '../../assets/image20.png'
import image21 from '../../assets/image21.png'
import image22 from '../../assets/image22.png'
import image23 from '../../assets/image23.png'
import image24 from '../../assets/image24.png'
import image25 from '../../assets/image25.png'
import image26 from '../../assets/image26.png'
import image27 from '../../assets/image27.png'
import image28 from '../../assets/image28.png'
import image29 from '../../assets/image29.png'
import image30 from '../../assets/image30.png'
import image31 from '../../assets/image31.png'
import image32 from '../../assets/image32.png'
import image33 from '../../assets/image33.png'
import image34 from '../../assets/image34.png'
import image35 from '../../assets/image35.png'
import image36 from '../../assets/image36.png'
import image37 from '../../assets/image37.png'
import image38 from '../../assets/image38.png'
import image39 from '../../assets/image39.png'
import image40 from '../../assets/image40.png'
import image41 from '../../assets/image41.png'
import image42 from '../../assets/image42.png'
import image43 from '../../assets/image43.png'
import image44 from '../../assets/image44.png'
import image45 from '../../assets/image45.png'
import image46 from '../../assets/image46.png'

export default function EquipamentosPage(){
    return (
        <div>
            <h1 className={style.title}>Uso dos equipamentos de proteção individual pelas equipes de saúde</h1>

            <p>A NR-06 do Ministério do Trabalho, publicada no D.O.U PARTE I, DECRETO nº 46.237 - de 18 junho de 1959, ou norma regulamentadora 6, foi criada para regulamentar o uso de Equipamentos de Proteção Individuais (EPIs), de modo que todas as empresas sejam obrigadas a adotar as mesmas medidas de proteção do funcionário em cenários semelhantes. Isso significa que todas as pessoas exercendo as mesmas funções devem ter um mesmo equipamento de proteção padronizado que leve em consideração as normas legais exigidas para essa função em específico.</p>
            <p>Em que pese a maior eficiência das medidas de proteção coletivas, o uso de EPIs é imprescindível para minimizar os riscos de contato de trabalhadores de saúde com o vírus SARS-CoV-2. 23, 24 Garantir o acesso aos EPIs recomendados a todos os trabalhadores e em quantidade e qualidade é responsabilidade do empregador, seja ele público ou privado, em regime da Consolidação das Leis do Trabalho (CLT) ou estatutário. Como também constituem obrigação do empregador o treinamento adequado dos trabalhadores, a supervisão do uso adequado e a manutenção e reposição necessários segundo o fabricante.</p>
            <p>É importante notar que esses EPIs precisam estar disponíveis em tamanho adequado aos usuários. Ressalta-se a necessidade do uso racional de EPI nos serviços de saúde, pois se trata de um recurso finito e imprescindível para oferecer segurança aos profissionais durante a assistência.</p>
            <p>Os tipos de equipamentos necessários para a prevenção da COVID-19 nos serviços de saúde são baseados nas tarefas executadas, mas de maneira geral, todos os EPIs devem:</p>
            <p>Ser selecionados com base no risco biológico a que os trabalhadores estão expostos; estar regularizados nos órgãos certificadores e na Anvisa;</p>
            <p>Ser usados adequadamente; ser higienizados e/ou descartados periodicamente, conforme recomendações técnicas e ser inspecionados, reparados e substituídos de acordo com instruções do fabricante. É importante lembrar que, em nenhuma hipótese, os EPIs de uso exclusivo no serviço de saúde devem ser levados para casa.</p>
            <p>Em geral, os EPIs que devem ser disponibilizados pelos serviços e utilizados pelos profissionais de saúde responsáveis pelo atendimento de casos suspeitos ou confirmados de COVID-19 são: 1) gorro; 2) óculos de proteção ou protetor facial; 3) máscara); 4) avental impermeável de mangas compridas; 5) luvas de procedimento. A figura 8, mais adiante, contém ilustrações pertinentes a essas medidas.</p>
            <p>Com relação ao tipo de máscara para procedimentos geradores de gotículas, o profissional precisa utilizar a máscara cirúrgica e as de proteção respiratória (respirador particulado) com eficácia mínima na filtração de 95% de partículas de até 0,3µ (tipo N95, N99, N100, PFF2 ou PFF3) sempre que realizar procedimentos geradores de aerossóis como, por exemplo, aspiração traqueal, inalação, indução de escarro, coletas de amostras nasotraqueais e broncoscopias.</p>
            <p>Os profissionais de saúde têm probabilidades maiores de se contaminarem diante dos frequentes procedimentos realizados nos pacientes infectados. Há ainda a possibilidade de complicações subsequentes que podem aumentar a disseminação do vírus em decorrência de certos procedimentos feitos por eles, como a aspiração traqueal e a intubação traqueal, ao executar a higienização oral e corporal do paciente, ao oferecer alimentação. Para que não haja a contaminação desses profissionais, é recomendada a utilização rigorosa e adequada dos EPIs.</p>

            <img className="img-fluid" src={image16} alt="Equipamentos" />

            <p>Para a utilização adequada dos EPIs, o profissional deve levar em consideração, não somente a eficiência necessária para o controle do risco de exposição, mas também o conforto ao portar esses materiais. Se há desconforto no uso do equipamento, há possibilidade de o profissional não o adotar e até mesmo não o incorporar na prática rotineira.</p>
            <p>Preconiza-se, como técnica de utilização de equipamento individual pelos profissionais da saúde: antes de iniciar a paramentação, lavar as mãos com água e sabão ou higienizar com solução alcoólica a 70%.</p>

            <h2 className={style.subtitle}>Fatores contributivos para a adesão dos profissionais de saúde no uso de Equipamento de Proteção Individual</h2>
            <p>Fatores que dificultam a adesão</p>
            <ul>
                <li>Diferentes percepções sobre o risco biológico de contágio entre os profissionais da equipe</li>
                <li>Falta de treinamento adequado sobre o uso dos EPIs e demais estratégias de enfrentamento</li>
                <li>Diretrizes de difícil compreensão, seja pelo método de transmissão, seja pela linguagem ambígua e linguagem pouco clara e pouco objetiva</li>
                <li>Contradições entre as diretrizes locais com as nacionais e internacionais e mudanças constantes</li>
                <li>Condições de trabalho e realidade dos serviços desfavoráveis à prática das diretrizes de enfrentamento da pandemia</li>
                <li>Aumento da carga de trabalho e fadiga devido aos procedimentos de paramentação constantes e à limpeza adicional requerida no ambiente</li>
                <li>Desconforto com o uso dos EPIs, sobretudo máscaras. Existência de programas de aprendizagem em serviço</li>
            </ul>

            <p>Fatores que favorecem a adesão</p>

            <ul>
                <li>Medo de infectar a si mesmos, suas famílias ou por se sentirem responsáveis pelo contágio de seus pacientes</li>
                <li>Existência de cultura organizacional do serviço favorável a práticas de segurança do paciente e comunicação efetiva</li>
                <li>Nível de apoio da equipe de gerentes percebido pela equipe de trabalho</li>
                <li>Decisões compartilhadas em relação ao planejamento e organização do processo de trabalho da equipe</li>
                <li>Realização de treinamento com o uso de metodologias ativas e participativas de ensino e aprendizagem</li>
                <li>Fornecimento de material educativo, tais como cartilhas e livretos</li>
                <li>Sentimento de distanciamento e isolamento em relação aos pacientes com risco de fortalecer estigmas</li>
            </ul>

            <h2 className={style.subtitle}>Técnica para paramentação dos equipamentos de proteção individual</h2>

            <p>Tipo de Equipamento de Proteção Individual necessário por atividades e paciente/profissional em domicílio</p>

            <div className={style.item}>
                <p><strong>Pacientes/profissional: </strong>Pessoas com sintomas respiratórios</p>
                <p><strong>Tipo de atividade: </strong>Qualquer atividade no domicílio</p>
                <p><strong>Medida de precaução e tipo de equipamento de proteção individual: </strong>Distanciar-se por pelo menos um metro. Usar máscara cirúrgica* se tolerado, exceto quando estiver dormindo</p>
            </div>

            <div className={style.item}>
                <p><strong>Pacientes/profissional: </strong>Cuidador</p>
                <p><strong>Tipo de atividade: </strong>Visita o quarto do paciente, mas sem prover cuidado ou assistência direta.</p>
                <p><strong>Medida de precaução e tipo de equipamento de proteção individual: </strong>Máscara cirúrgica*</p>
            </div>

            <div className={style.item}>
                <p><strong>Pacientes/profissional: </strong>Cuidador</p>
                <p><strong>Tipo de atividade: </strong>Cuida diretamente, faz a limpeza, ou quando manipula fezes, urina ou lixo de pessoa com COVID-19 em cuidado domiciliar.</p>
                <p><strong>Medida de precaução e tipo de equipamento de proteção individual: </strong>Luvas; Máscara cirúrgica* e avental (se houver risco de respingo)</p>
            </div>

            <div className={style.item}>
                <p><strong>Pacientes/profissional: </strong>Profissional da saúde</p>
                <p><strong>Tipo de atividade: </strong>Prestação de cuidado direto ou assistência à pessoa com COVID-19 em casa</p>
                <p><strong>Medida de precaução e tipo de equipamento de proteção individual: </strong>Máscara Cirúrgica**; Avental; Luvas; Óculos</p>
            </div>

            <p><strong>Legenda</strong>:*Não havendo disponibilidade de máscara cirúrgica descartável, é aconselhável o uso de máscara caseira.**Em caso de não haver disponibilidade de máscara cirúrgica descartável, é aconselhável avaliar riscos do contato com a pessoa doente, ausência de alternativas seguras de EPI e notificar gestor/responsável técnico. EPI = Equipamento de proteção individual.</p>

            <h2 className={style.subtitle}>Protocolo de paramentação e desparamentação</h2>

            <p>Paramentação</p>

            <ul>
                <li>1º Avental ou capote </li>
                <li>2º Máscara cirúrgica ou Máscara de proteção respiratória</li>
                <li>3º Óculos ou protetor facial </li>
                <li>4º Gorro ou touca* </li>
                <li>5º Luvas </li>
                <li>*No caso de procedimentos geradores de aerossóis.</li>
            </ul>

            <p>Desparamentação</p>

            <ul>
                <li>1º Luvas</li>
                <li>2º Avental ou capote</li>
                <li>3º Óculos ou protetor facial*</li>
                <li>4º Gorro ou touca*</li>
                <li>5º Máscara cirúrgica ou de proteção respiratória*</li>
                <li>OBS: Exceto pela máscara, remova o EPI ainda no quarto, próximo à saída, ou na antessala. Remova a máscara somente após deixar o quarto do paciente e fechar a porta.</li>
            </ul>

            <p>Deve-se passar álcool nas mãos depois de cada passo descrito acima e posteriormente a tudo lavar as mãos com água e sabão.</p>

            <h2 className={style.subtitle}>Paramentação do avental ou capote </h2>

            <p>O avental deve ter gramatura mínima de 30 g/m2; seu uso evita a contaminação da pele e da roupa do profissional. O avental impermeável deve ser usado para atendimentos de pessoas suspeitas ou contaminadas pela COVID-19.</p>
            <p>O avental deve ter mangas longas, punho de malha ou elástico e abertura posterior. Além disso, deve ser confeccionado com material de boa qualidade, atóxico. </p>
            <p>Sugere-se que o avental, impermeável, após uso, seja considerado contaminado, devendo ser removido e descartado como resíduo infectante após a execução do procedimento do paciente com COVID-19 antes de sair do quarto de isolamento.</p>

            <h2 className={style.subtitle}>Técnica de paramentação do avental ou capote</h2>

            <ul>
                <li>
                    <p>Vista o avental ou capote primeiramente pelas mangas, ajustando as amarras nas costas e cintura.</p>
                    <img src={image17} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p>Certifique-se de que o tronco esteja totalmente coberto, bem como os braços e os punhos.</p>
                    <img src={image18} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p>Prenda o avental  na parte superior com um laço, em seguida prenda a parte da cintura.</p>
                    <img src={image19} className="img-fluid" alt="Paramentação" />
                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica de desparamentação do avental ou capote</h2>

            <ul>
                <li>
                    <p>Durante a retirada do avental ou capote, evite tocar o lado externo, pois estará contaminado.</p>
                </li>
                <li>
                    <p>Abra as tiras e solte as amarras. Primeiro as do laço localizado na cintura e depois as do laço superior</p>
                    <img src={image20} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p>Empurre pelo pescoço e pelos ombros, tocando apenas a parte interna do avental/capote. </p>
                    <img src={image21} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p> 	Retire o avental/capote pelo avesso. </p>
                    <img src={image22} className="img-fluid" alt="Paramentação" />
                </li>
                <li>Dobre ou enrole em uma trouxa e descarte em recipiente apropriado.</li>
            </ul>

            <h2 className={style.subtitle}>Máscara</h2>

            <p>As máscaras funcionam como a combinação básica de EPIs para o profissional da saúde. São fundamentais, pois servem como proteção contra bactérias, fungos e outros agentes contaminantes que possam ser inalados. Seguem, subsequente, fotos dos tipos de máscara.</p>

            <img src={image23} className="img-fluid" alt="Paramentação" />

            <h2 className={style.subtitle}>Máscara Cirúrgica</h2>

            <p>O uso da máscara cirúrgica para pacientes e profissionais de saúde reduz a transmissão de gotículas, quando nos casos de contato com pessoas suspeitas de SARS-CoV-2 e com sintomas respiratórios brandos, desde a chegada ao serviço de saúde, ao local de isolamento e durante a circulação dentro do serviço (transporte de uma área/setor para outro), devendo-se evitar ao máximo tocar a máscara, olhos, boca e face.</p>
            <p>As máscaras cirúrgicas não devem ser sobrepostas às máscaras tipo N95, N99, N100, PFF2 ou PFF3, por não garantirem proteção de filtração ou de contaminação. A máscara N95 ou equivalente poderá ser reutilizada pelo mesmo profissional, desde que cumpridos os passos obrigatórios para sua retirada sem a contaminação do seu interior</p>

            <h2 className={style.subtitle}>Técnica de colocação da máscara cirúrgica </h2>

            <ul>
                <li>Faça a higienização das mãos com água e sabão ou higienize com solução alcoólica a 70%.</li>
                <li>Verifique se a máscara não está danificada. </li>
                <li>Utilize o clip nasal como referência para identificar a parte superior. </li>
                <li>
                    <p>Coloque a máscara em seu rosto e prenda as alças atrás da cabeça, mantendo-as paralelas (nunca cruzadas).</p>
                    <img src={image24} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p>Aperte o clip nasal ou a borda rígida da máscara para que ela se adapte ao formato do seu nariz, visando minimizar espaços entre a face e a máscara</p>
                    <img src={image25} className="img-fluid" alt="Paramentação" />
                </li>
                <li>
                    <p>Puxe a parte inferior da máscara para que ela cubra sua boca e seu queixo.</p>
                    <img src={image26} className="img-fluid" alt="Paramentação" />
                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica de retirada da máscara cirúrgica</h2>
            
            <ul>
                <li>
                    <p>Durante a retirada da máscara, o profissional deve evitar tocar a parte frontal, pois ela poderá estar contaminada. </p>
                </li>
                <li>
                    <p>Segure as alças inferiores e depois as alças ou elástico superiores e remova a máscara</p>
                    <img className="img-fluid" src={image27} alt="Técnica Máscara" />
                </li>
                <li>
                    <p>Descarte a máscara em uma lixeira. </p>
                    <img className="img-fluid" src={image28} alt="Técnica Máscara" />
                </li>
                <li>Lave as mãos com água e sabão ou higienize com solução alcoólica a 70%.</li>
            </ul>

            <p><strong>Observação</strong></p>
            <p>Não reutilize máscaras descartáveis. Enquanto o profissional estiver usando a máscara, deve evitar tocá-la na parte da frente. </p>

            <p> 	O profissional deve trocar a máscara, quando ela estiver úmida ou sempre que for necessário. </p>

            <img className="img-fluid" src={image29} alt="Técnica Máscara" />

            <h2 className={style.subtitle}>Máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3 </h2>

            <p>Deve ser utilizada quando o profissional for executar os seguintes procedimentos: induzir a tosse, intubação ou aspiração traqueal, ventilação invasiva e não invasiva, ressuscitação cardiopulmonar, ventilação manual antes da intubação, indução de escarro. Em coleta de amostra nasotraqueal em paciente com suspeita ou infecção confirmada pelo Sars-CoV-2, que pode gerar aerossóis, os profissionais devem usar a máscara de proteção respiratória com eficácia mínima na filtração de 95% de partículas de até 0,3 µ (tipo N95, N99, N100, PFF2 ou PFF3).</p>
            <p>Visando minimizar a contaminação da máscara N95 ou equivalente, se houver disponibilidade, pode ser usado um protetor facial (face shield) e, se a máscara estiver íntegra, limpa e seca, pode ser usada várias vezes durante o mesmo plantão pelo mesmo profissional (até 12 horas ou conforme definido pela Comissão de Controle de Infecção Hospitalar – CCIH – do serviço de saúde).</p>

            <h2 className={style.subtitle}>Técnica da colocação da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF </h2>

            <ul>
                <li>
                    <p>Segurar o respirador com o clip nasal próximo à ponta dos dedos, deixando as alças pendentes, conforme a figura 15. </p>
                </li>
                <li>
                    <p>Encaixar o respirador sob o queixo.</p>
                    <img className="img-fluid" src={image30} alt="Técnica" />
                </li>
                <li>
                    <p>Posicionar uma das alças na nuca e a outra na cabeça.</p>
                    <img className="img-fluid" src={image31} alt="Técnica" />
                </li>
                <li>
                    <p>Ajustar o clip nasal no nariz.</p>
                    <img className="img-fluid" src={image32} alt="Técnica" />
                </li>
                <li>
                    <p>Verificar a vedação pelo teste de pressão positiva e negativa</p>
                    <img className="img-fluid" src={image33} alt="Técnica" />
                </li>
                <li>
                    <p>Observação: Expire profundamente. Uma pressão positiva dentro da máscara significa que não tem vazamento. </p>
                </li>
                <li>
                    <p>Se houver vazamento, ajuste a posição e/ou as alças de tensão. Teste novamente a vedação. </p>
                </li>
                <li>
                    <p>Repita os passos até que a máscara esteja vedando corretamente. </p>
                </li>
                <li>
                    <p>Inspire profundamente. Se não houver vazamento, a pressão negativa fará o respirador agarrar-se no seu rosto. </p>
                </li>
                <li>
                    <p>O vazamento resultará em perda de pressão negativa na máscara devido à entrada de ar através de lacunas na vedação. </p>
                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica de retirada da máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3 </h2>

            <ul> 
                <li>
                    <p>Segurar o elástico superior com as duas mãos, passando-o por cima da cabeça para removê-lo.</p>
                    <img className="img-fluid" src={image34} alt="Máscara" />
                </li>
                <li>
                    <p>Remover a máscara, segurando-a pelos elásticos, tomando bastante cuidado para não tocar na superfície interna. </p>
                    <img className="img-fluid" src={image35} alt="Máscara" />
                </li>
                <li>
                    <p>Acondicionar a máscara em um saco ou envelope de papel com os elásticos para fora para facilitar a retirada posterior no caso de reutilização</p>
                    <img className="img-fluid" src={image36} alt="Máscara" />
                </li>
                <li>
                    <p>Nunca colocar a máscara já utilizada em um saco plástico, pois ela poderá ficar úmida e potencialmente contaminada. </p>
                    <img className="img-fluid" src={image37} alt="Máscara" />
                </li>
                <li>
                    <p>Fazer a higienização das mãos com água e sabão ou higienizá-las com solução alcoólica a 70%. </p>
                </li>
            </ul>


            <h2 className={style.subtitle}>Técnica de colocação de óculos protetores e Face Shield </h2>

            <p>Colocar os óculos sobre a face, deixando confortável. Os óculos devem ser de uso exclusivo para cada profissional responsável pela assistência.</p>

            <img src={image38} className="img-fluid" alt="Óculos"/>

            <ul>
                <li>
                    <p>O Protetor Facial (Face Shield) deverá ser utilizado para os atendimentos e transportes de pacientes com sintomas respiratórios, suspeitos ou confirmados com COVID-19. </p>

                </li>
                <li>
                    <p>Ajustar o Protetor Facial (Face Shield) de modo que fique firme no seu rosto.</p>
                    <img className="img-fluid" src={image39} alt="Óculos" />
                </li>
                <li>
                    <p>Os protetores faciais devem ser utilizados sobre as máscaras (máscara de proteção respiratória tipo N95, N99, N100, PFF2 ou PFF3) e sobre os óculos de proteção. </p>

                </li>
                <li>
                    <p>Colocar o Protetor Facial (Face Shield) iniciando por cima da cabeça.</p>
                    <img className="img-fluid" src={image40} alt="Óculos" />
                </li>
                <li>
                    <p>O protetor facial deve ser de uso individual e cada profissional deve efetuar a higiene do seu equipamento após cada atendimento ou transporte.  </p>

                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica de retirada do protetor facial (Face Shield) </h2>

            <ul>
                <li>
                    <p>Retirar o protetor facial (Face Shield) de acordo com a ilustração </p>
                    <img className="img-fluid" src={image41} alt="Óculos" />
                </li>
                <li>
                    <p>Após o seu uso, o profissional deverá fazer a assepsia, (caso não possa ser descartado) de acordo com protocolo da CCIH do serviço de saúde. </p>
                </li>
            </ul>

            <h2 className={style.subtitle}>Touca</h2>
            <p>A utilização da touca tem por finalidade proteger de forma dupla, tanto contra partículas que possam contaminar os profissionais, quanto da queda de cabelos ou outros componentes em materiais do trabalho.</p>

            <h2 className={style.subtitle}>Técnica de uso da touca </h2>

            <ul>
                <li>
                    <p>Colocar o gorro ou a touca na cabeça, começando pela testa em direção à base da nuca. </p>
                </li>
                <li>
                    <p>Adaptar à cabeça de modo confortável, cobrindo todo o cabelo e as orelhas. </p>
                    <img className="img-fluid" src={image42} alt="Óculos" />
                </li>
                <li>
                    <p>Sempre que o gorro ou a touca aparentarem sinais de umidade, devem ser substituídos por outro/a.</p>
                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica da retirada da touca</h2>

            <ul>
                <li>
                    <p>Para retirar a touca/gorro, puxe pela parte superior central sem tocar nos cabelos. </p>
                </li>
                <li>
                    <p>Descarte a touca/gorro em recipiente apropriado. </p>
                    <img className="img-fluid" src={image43} alt="Óculos" />
                </li>
                <li>
                    <p>Higienize as mãos com água e sabão ou higienize com solução alcoólica a 70%.</p>
                </li>
            </ul>

            <h2 className={style.subtitle}>Uso das luvas  </h2>

            <p>O uso da luva tem como finalidade impedir que o profissional tenha contato direto com agentes físicos e biológicos. </p>
            <p>Existem três tipos de luvas, sendo que cada um serve para manipular tipos específicos de material:</p>
            <p><strong>Luvas de látex</strong>: são feitas de material de borracha natural, podendo serem encontradas com ou sem talco.</p>
            <p><strong>Luvas de nitrílica</strong>: apesar de também serem produzidas com material de borracha, são mais resistentes a óleo, gorduras vegetais e animais, além de água quente por um curto tempo.</p>
            <p><strong>Luvas de vinil</strong>: compostas por um tipo de plástico com ou sem talco.</p>

            <h2 className={style.subtitle}>Técnica de colocação das luvas</h2>

            <ul>
                <li>
                    <p>Calce as luvas e estenda-as até cobrir o punho do avental de isolamento. </p>
                </li>
                <li>
                    <p>Troque as luvas sempre que for necessário ou quando for entrar em contato com outro paciente. </p>
                    <img className="img-fluid" src={image44} alt="Óculos" />
                </li>
                <li>
                    <p>Troque as luvas durante o contato com o paciente, se for mudar de um sítio corporal contaminado para outro limpo ou quando essa estiver danificada.</p>
                </li>
                <li>
                    <p>Nunca toque desnecessariamente em superfícies e materiais (tais como telefones, maçanetas, portas), quando estiver com luvas. </p>
                </li>
                <li>
                    <p>Não lave ou use novamente o mesmo par de luvas. As luvas não devem ser reutilizadas. </p>
                </li>
                <li>
                    <p>Proceda à higiene das mãos imediatamente após a retirada das luvas. </p>
                </li>
            </ul>

            <h2 className={style.subtitle}>Técnica de retirada das luvas  </h2>

            <ul>
                <li>
                    <p>Durante a retirada das luvas, o profissional deve evitar tocar o lado externo, pois estará contaminado.</p>
                </li>
                <li>
                    <p>Com as duas mãos enluvadas, segure a parte externa de uma luva na parte superior do pulso.</p>
                    <img className="img-fluid" src={image45} alt="Óculos" />
                </li>
                <li>
                    <p>Retire a primeira luva, afastando-a do corpo e do pulso até as pontas dos dedos, virando a luva de dentro para fora. </p>
                    <img className="img-fluid" src={image46} alt="Óculos" />
                </li>
                <li>
                    <p>Segure a luva que você acabou de remover em sua mão enluvada. </p>
                </li>
                <li>
                    <p>Descarte as luvas na lixeira. Não reutilize as luvas. </p>
                </li>
                <li>
                    <p>Lave as mãos com água e sabão ou higienize com solução alcoólica a 70%.</p>
                </li>
            </ul>
        </div>
    )
}