import style from './style.module.css'

export default function ReferenciasPage(){
    return (
        <div>
            <h1 className={style.title}>Referências</h1>
            <ul>


            <li>
                <p>Abboah-Offei M, Salifu Y, Adewale B, Bayuo J, Ofosu-Poku R, Opare-Lokko EBA.</p>
                <p>A rapid review of the use of face mask in preventing the spread of COVID-19.</p>
                <p>Int J Nurs Stud Adv. 2021;3:100013. doi: 10.1016/j.ijnsa.2020.100013.</p>
            </li>

            <li>
                <p>Li Y, Liang M, Gao L, Ayaz Ahmed M, Uy JP, Cheng C, Zhou Q, Sun C.</p>
                <p>Am J Infect Control. 2020.6553(20)31043-9. doi: 10.1016/j.ajic.2020.12.007.</p>
                <p>Face masks to prevent transmission of COVID-19: A systematic review and meta-analysis.</p>
            </li>


            <li>
                <p>Flumignan RLG, Nakano LCU, Pascoal PIF, Santos BC, Correia RM, Silveira BP, Takihi FA, Flumignan CDQ, Amorim JE, Atallah NA.</p>
                <p>Evidence from Cochrane systematic reviews for controlling the dissemination of COVID-19 infection. A narrative review</p>
                <p>Sao Paulo Med J. 2020; 138(4):336-44.DOI: 10.1590/1516-3180.2020.029105062020</p>
            </li>


            <li>
                <p>Houghton C, Meskell P, Delaney H, Smalle M, Glenton C, Booth A, Chan XHS, Devane D, Biesty LM.</p>
                <p>Barriers and facilitators to healthcare workers’ adherence with infection prevention and control (IPC) guidelines for respiratory infectious diseases: a rapid qualitative evidence synthesis</p>
                <p>Cochrane Database of Systematic Reviews 2020, Issue 4. Art. No.: CD013582. DOI: 10.1002/14651858.CD013582.</p>
            </li>


            <li>
                <p>Nussbaumer-Streit B, Mayr V, Dobrescu AI, Chapman A, Persad E, Klerings I, Wagner G, Siebert U,</p>
                <p>Quarantine alone or in combination with other public health measures to control COVID-19: a rapid review.</p>
                <p>Cochrane Database of Systematic Reviews 2020, Issue 9. Art. No.: CD013574. DOI: 10.1002/14651858.CD013574.pub2. Ledinger D, Zachariah C, Gartlehner G.</p>
            </li>

            <li>
                <p>Jefferson T, Del Mar CB, Dooley L, Ferroni E, Al-Ansary LA, Bawazeer GA, van Driel ML, Nair S, Jones MA, Thorning S, Conly JM</p>
                <p>Physical interventions to interrupt or reduce the spread of respiratory viruses.</p>
                <p>Cochrane Database of Systematic Reviews 2011, Issue 7. Art. No.: CD006207. DOI: 10.1002/14651858.CD006207.pub4</p>
            </li>


            <li>
                <p>Verbeek JH, Rajamaki B, Ijaz S, Sauni R, Toomey E, Blackwood B, Tikka C, Ruotsalainen JH, Kilinc Balci FS. 20</p>
                <p>Personal protective equipment for preventing highly infectious diseases due to exposure to contaminated body fluids in healthcare staff.</p>
                <p>Cochrane Database of Systematic Reviews 2020, Issue 5. Art.No.: CD011621. DOI: 10.1002/14651858.CD011621.pub5.</p>
            </li>


            <li>
                <p>Struyf T, Deeks JJ, Dinnes J, Takwoingi Y, Davenport C, Leeflang MMG, Spijker R, Hooft L, Emperador D, Dittrich S, Domen J, Horn SR A, Van den Bruel A.</p>
                <p>Signs and symptoms to determine if a patient presenting in primary care or hospital outpatient settings has COVID-19 disease.</p>
                <p>Cochrane Database of Systematic Reviews 2020, Issue 7. Art. No.: CD013665. DOI: 10.1002/14651858.CD013665.</p>
            </li>


            <li>
                <p>Taminato M, Mizusaki-Imoto A, Saconato H, Franco ES, Puga ME, Duarte ML, et al.</p>
                <p>Homemade cloth face masks as a barrier against respiratory droplets - systematic review.</p>
                <p>Acta Paul Enferm. 2020:eAPE20200103.http://dx.doi.org/10.37689/acta-ape/2020ar0103 </p>
            </li>


            <li>
                <p>Deeks JJ, Dinnes J, Takwoingi Y, Davenport C, Spijker R, Taylor- Phillips S, Adriano A, Beese S, Dretzke J, Ferrante di Ruffano L, Harris IM, Price MJ, Dittrich S, Emperador D, Hooft L, Leeflang</p>
                <p>Antibody tests for identification of current and past infection with SARS-CoV-2.</p>
                <p>Cochrane Database of Systematic Reviews 2020, Issue 6. Art. No.: CD013652. DOI: 10.1002/14651858.CD013652. MMG, Van den Bruel A.</p>
            </li>

            <li>
                <p>Silva ESPe, Prado RFS, Borrajo APBC, Façanha ST, Martins WA.</p>
                <p>Biossegurança frente a saúde e aos riscos ocupacionais para equipe de enfermagem atuante na assistência ao paciente com covid-19.</p>
                <p>Braz. J. of Develop.2020; 6(7): 42060-52068. DOI:10.34117/ bjdv6n7-742,</p>
            </li>


            <li>
                <p>Lima MMS, Cavalcante FML, Macêdo TS, Galindo-Neto NM, Caetano JA, Barros LM</p>
                <p>Cloth face masks to prevent Covid-19 and other respiratory infections</p>
                <p>Rev. Latino-Am. Enfermagem. 2020;28:e3353.DOI: http://dx.doi.org/10.1590/1518-8345.4537.3353.</p>
            </li>


            <li>
                <p>Tonin L, Lacerda MR, Caceres NTG, Hermann AP.</p>
                <p>Recommendations in covid-19 times: a view for home care.</p>
                <p>Rev Bras Enferm. 2020;73Suppl 2(Suppl 2):e20200310. doi: 10.1590/0034-7167-2020-0310.</p>
            </li>


            <li>
                <p>Oliveira HC, Souza LC, Leite TC, Campos JF.</p>
                <p>Personal Protective Equipment in the coronavirus pandemic:training with Rapid Cycle Deliberate Practice</p>
                <p>Rev Bras Enferm. 2020;73(Suppl 2):e20200303. doi: http://dx.doi.org/10.1590/0034-7167-2020-0303</p>
            </li>


            <li>
                <p>Cavalcante ES, Pedrosa IMB, Oliveira LV, Pessoa Júnior JM, Pennafort VPS, Machado FCA.</p>
                <p>Cartilha para enfrentamento do COVID- 19 em comunidades quilombolas: relato de experiência</p>
                <p>Rev Recien. 2020; 10(31):174-182.doi.org/10.24276/rrecien2020.10.31.174-182</p>
            </li>

            <li>
                <p>Silva ACO, Almeida AM, Freire MEM, Nogueira JA, Gir E, Nogueira WP.</p>
                <p>Cloth masks as respiratory protections in the COVID-19 pandemic period: evidence gaps.</p>
                <p>Rev Bras Enferm. 2020;73(Suppl 2):e20200239. doi: http://dx.doi.org/10.1590/0034-7167-2020-0239</p>
            </li>


            <li>
                <p>González FA </p>
                <p>Coronavirus disease 2019: the importance of social distancing</p>
                <p>Medicina (Ribeirão Preto) 2020;53(3):223-233 doi.org/10.11606/issn.2176-7262.v53i3p223-23</p>
            </li>

            <li>
                <p>Farias LABG, Colares MP, Barretoti FKA, Cavalcanti LPG.</p>
                <p>O papel da atenção primária no combate ao Covid-19: impacto na saúde pública e perspectivas futuras.</p>
                <p>Rev Bras Med Fam Comunidade. 2020;15(42):2455. https://doi.org/10.5712/rbmfc15(42)2455</p>
            </li>


            <li>
                <p>Savassi LCM, Reis GVL, Dias MB, Vilela LO, Ribeiro MTAM, Zachi MLR, Nunes MRPTM.</p>
                <p>Recomendações para a Atenção Domiciliar em período de pandemia por COVID-19.</p>
                <p>Rev Bras Med Fam Comunidade. 2020;15(42):2611. https://doi.org/10.5712/rbmfc15(42)2611</p>
            </li>


            <li>
                <p>Daumas RP, Silva GA, Tasca R, Leite IC, Brasil Pa, Greco DiB. et al</p>
                <p>The role of primary care in the Brazilian healthcare system: limits and possibilities for fighting COVID-19</p>
                <p>Cad. Saúde Pública. 2020;  36( 6 ): e00104120.  https://doi.org/10.1590/0102-311x00104120</p>
            </li>


            <li>
                <p>Gallasch CH, Cunha ML, Pereira LAS, Silva-Junior JS</p>
                <p>Prevenção relacionada à exposição ocupacional: COVID-19.</p>
                <p>Rev enferm UERJ. 2020; 28:e49596 . DOI: http://dx.doi.org/10.12957/reuerj.2020.49596</p>
            </li>

            </ul>
        </div>
    )
}