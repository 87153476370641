import style from './style.module.css'

import image47 from '../../assets/image47.png'
import image48 from '../../assets/image48.png'
import image49 from '../../assets/image49.png'

export default function OrientacoesPage(){
    return (
        <div>
            <h1 className={style.title}>Orientações para diminuir o risco da  COVID-19  em nível domiciliar</h1>

            <p>Para que o profissional de saúde possa prevenir-se de contrair e transmitir  a infecção pela COVID-19 para os pacientes, familiares e cuidadores, durante a visita domiciliar, deve sequir rigorosamente as medidas de prevenção de transmissão, paramentação e desparamentação dos EPIs, fornecer as orientações sobre as medidas preventivas para os pacientes, cuidadores e familiares. Recomenda-se que a equipe realize o telemonitoramento dos casos que são acompanhados pela equipe, a fim de, além do acompanhamento clínico habitual, também investigar a possibilidade de sintomas respiratórios no paciente e/ou nos demais moradores e frequentadores do domicílio, como o cuidador.</p>
            <p>Caso sejam identificados casos suspeitos ou confirmados intradomicílio, faz-se   necessária a paramentação correta de todos integrantes da equipe antes de entrar na casa, sendo indicado que apenas os profissionais extremamente essenciais destacados para aquele cuidado visitem o paciente.</p>
            <p>A equipe do SAD deve garantir a presença de kits de EPIs completos para paramentação nos seus veículos de visita domiciliar, com a possibilidade de acesso a esses equipamentos sempre que for identificada a necessidade do uso durante o atendimento.. A equipe deve evitar o consumo de água e alimentos na casa dos pacientes.</p>
            <p>Na sequência, estão os itens que compõem as orientações a serem dadas pela equipe de profissionais ao paciente, familiares e cuidadores.</p>


            <h2 className={style.subtitle}>Orientações para os pacientes</h2>

            <ul>
                <li>Permaneça em um quarto individual bem ventilado (ou seja, com janelas abertas e uma porta aberta). </li>
                <li>Evite compartilhamento de espaços. </li>
                <li>Não receba visitas até sua completa recuperação e remissão dos sinais ou sintomas de COVID19. Faça a higiene de mãos frequentemente e também antes de comer, após o uso do banheiro e sempre que as mãos parecerem sujas, utilizando água e sabão. </li>
                <li>Utilize toalhas de papel descartáveis para secar as mãos após lavá-las com água e sabão. Se não estiverem disponíveis, use toalhas de pano limpas e substitua-as com frequência. Use máscara cirúrgica para conter secreções respiratórias (7), devendo trocá-la sempre que estiver úmida e evitando manuseá-la. </li>
                <li>Realize higiene respiratória rigorosa, quando não tolerar uma máscara cirúrgica, isto é, cubra a boca e o nariz com um lenço de papel descartável ao tossir ou espirrar, descartando-os em seguida e lavando as mãos. Caso utilize lenços de pano, lave-os com água e sabão. </li>
                <li>Limpe e desinfete diariamente as superfícies que são frequentemente tocadas, como mesas de cabeceira, quadros de cama e outros móveis de quarto e banheiro, quando for de uso particular. Utilize primeiramente sabão ou detergente comum e, depois de enxaguar, aplique um desinfetante comum contendo hipoclorito de sódio a 0,1% (ou seja, equivalente a 1.000 ppm).</li>
                <li>Mantenha uma lixeira tampada e revestida com saco plástico no quarto. </li>
                <li>Coloque a roupa contaminada em um saco de roupa. </li>
            </ul>

            <h2 className={style.subtitle}>Orientação para os cuidadores familiares</h2>

            <ul>
                <li>Minimize o espaço compartilhado. </li>
                <li>Os membros da família devem ficar em uma sala diferente ou, se isso não for possível, manter uma distância de pelo menos um metro da pessoa doente.</li>
                <li>Limite o número de cuidadores. </li>
                <li>Proceda à higiene das mãos após qualquer tipo de contato com os pacientes ou seu ambiente imediato, antes e após a preparação dos alimentos, antes de comer, após o uso do banheiro e sempre que as mãos parecerem sujas, utilizando água e sabão.</li>
                <li>Se as mãos não estiverem visivelmente sujas, pode ser usada fricção à base de álcool a 70%. </li>
                <li>Utilize toalhas de papel descartáveis para secar as mãos após lavá-las com água e sabão. Se não estiverem disponíveis, use toalhas de pano limpas e substitua-as com frequência. </li>
                <li>Limpe e desinfete as superfícies do banheiro pelo menos uma vez ao dia. Utilize primeiramente sabão ou detergente comum e, depois de enxaguar, aplique um desinfetante comum contendo hipoclorito de sódio a 0,1% (ou seja, equivalente a 1.000 ppm) (3). </li>
                <li>Utensílios usados pelo paciente devem ser limpos com água e detergente doméstico comum após o uso e podem ser reutilizados (preferencialmente pelo paciente) em vez de serem descartados.</li>
                <li>Lave roupas de cama à máquina/tanque com sabão em pó comum, água quente, entre 60 °C e 90 °C (140 °F a 194 °F) e seque-as bem. </li>
                <li>Luvas e roupas de proteção devem ser usados ao limpar superfícies ou ao manusear roupas ou lençóis sujos com fluidos corporais. </li>
                <li>Dependendo do contexto, luvas de borracha (reutilizáveis) ou descartáveis podem ser usadas. Após o uso, as luvas de borracha devem ser limpas com água e sabão e descontaminadas com solução de hipoclorito de sódio a 0,1%. Luvas descartáveis devem ser desprezadas após cada uso. Faça a higiene das mãos antes de colocar e depois de remover as luvas. </li>
                <li>Evite outros tipos de exposição a itens contaminados do ambiente imediato do paciente (p.ex., não compartilhe escovas de dentes, cigarros, utensílios de cozinha, pratos, bebidas, toalhas, panos ou roupas de cama). </li>
                <li>Luvas, máscaras e outros resíduos gerados durante o atendimento domiciliar devem ser colocados em uma lixeira com uma tampa no quarto do paciente antes de descartá-los como lixo infeccioso. </li>
                <li>O descarte de resíduos infecciosos deve ser previsto e fornecido pelas autoridades sanitárias locais. </li>
            </ul>

            <h2 className={style.subtitle}>Uso da máscara caseira</h2>

            <p>O rápido crescimento do COVID-19, cuja transmissão se dá por meio de gotículas respiratórias, expelidas quando um infectado tosse, espirra ou fala próximo a outras pessoas, também por via de superfícies ou objetos contaminados, deixou a população mundial em risco para ser contaminada, fazendo com que as pessoas começassem a fazer  a adesão efetiva às recomendações preventivas, como lavagem das mãos, etiqueta respiratória ao tossir ou espirrar, uso de máscaras e distanciamento social.</p>
            <p>Um dos meios de medidas preventivas é a utilização da máscara como barreira mecânica para a prevenção da dispersão de gotículas, além da prática frequente de higienização das mãos por meio de água e sabão ou com soluções à base de álcool.52 A finalidade é a autoproteção contra as doenças e, também, para prevenir a transmissão de patógenos entre os indivíduos doentes e os saudáveis. As máscaras têm sido utilizadas como uma intervenção popular de saúde pública.</p>
            <p>As máscaras de tecidos estão sendo indicadas para as pessoas que precisam sair de casa para a realização de atividades tidas como essenciais a fim de reduzir a incidência de contaminação que leve à COVID-19.55 É indicado o uso por qualquer pessoa, porém, para isso, devem ser respeitados: a tolerância, o ajuste e a higiene do material, não deixando de seguir as outras medidas de prevenção, como o distanciamento de um metro de outras pessoas.</p>
            <p>Segundo Lima et al.51, as máscaras de tecido representam medida preventiva com eficácia moderada na disseminação de infecções respiratórias ocasionadas por partículas de tamanho igual e/ou menor ao SARS-CoV-2. A eficácia da barreira contra as gotículas é influenciada, principalmente, pelo tipo de tecido, quantidade de camadas e frequência de lavagens.</p>
            <p>Com relação aos tipos de tecidos, sugere-se a utilização de tecidos de poliéster, algodão, seda natural e chiffon, por já terem apresentado, em alguns estudos promissores, níveis de bloqueio de vírus em aerossóis, embora ainda sejam necessárias mais pesquisas para a efetiva comprovação.</p>
            <p>Ainda com relação à utilização da máscara de tecido, o CDC dos Estados Unidos recomenda que, na ausência da máscara artesanal, a população, em geral, utilize cobertura de tecido para cobrir o nariz e a boca a fim de evitar a contaminação das pessoas ao redor, principalmente nos quadros assintomáticos. Essa medida não se destina a proteger o indivíduo, mas sim impedir ou reduzir a propagação de vírus de um ser humano para o outro.</p>
            <p>As máscaras de tecido apresentaram variação nos valores de penetração para partículas polidispersas (40-90%) e aerossol monodisperso (40-97%), o que indica proteção respiratória marginal.</p>
            <p>É importante que a população tenha em mente que as máscaras de tecido devem ser usadas em conjunto com outras medidas preventivas, como isolamento domiciliar, boa etiqueta respiratória e higiene regular das mãos.</p>
            <p>Antes de colocar uma máscara, deve-se higienizar as mãos adequadamente. A máscara deverá cobrir a boca e o nariz, deve estar íntegra, não pode haver espaços entre o rosto e a máscara: para isso, não se devem cruzar os elásticos ou tiras em decorrência da abertura resultante. Após a utilização, a remoção deve ser feita tirando os elásticos de trás das orelhas ou desamarrando as tiras; não se deve tocar a parte frontal durante o uso ou retirada, porém, se assim o fizer, realizar novamente a higienização das mãos. Quanto aos profissionais de saúde, deve-se evitar a utilização de batons ou outros produtos de maquiagem enquanto estiverem fazendo uso de máscara.</p>

            <img className="img-fluid" src={image47} alt="Máscara Caseira" />

            <p>O que não pode fazer com a máscara caseira</p>

            <img className="img-fluid" src={image48} alt="Máscara Caseira" />

            <h2 className={style.subtitle}>Técnica da retirar da máscara caseira</h2>

            <img className="img-fluid" src={image49} alt="Máscara Caseira" />

        </div>
    )
}