import style from './style.module.css'

import image50 from '../../assets/image50.png'
import image51 from '../../assets/image51.png'

export default function AlgoritmosPage(){
    return (
        <div>
            <h1 className={style.title}>Algoritmos</h1>

            <p>É importante a construção de tecnologia educativa, manuais, cartilhas, algoritmos e aplicativos que ofereçam informações apropriadas sobre as técnicas do uso de EPIs e sobre ações preventivas e condutas terapêuticas para lesões cutâneas faciais causadas pelo uso de EPIs. Assim, ao utilizar esta tecnologia, o profissional estará prestando assistência livre de danos, com segurança e sem risco para o paciente, evitando contaminar-se e prevenindo-se de lesões cutâneas faciais.</p>
            <p>Algoritmos são constituídos por uma sequência finita de instruções bem definidas e realizadas sistematicamente. Eles são instrumentos simples, diretos e de fácil acesso, empregados comumente no âmbito da saúde e conferem uma visão completa do processo clínico, auxiliando na tomada de decisões.</p>

            <h2 className={style.subtitle}>Algoritmo para orientar profissionais da saúde na utilização da técnica da paramentação dos equipamentos de proteção individual durante a pandemia da COVID-19.</h2>

            <img className="img-fluid" src={image50} alt="Algoritmo"/>

            <p>Algoritmo para desparamentação de equipamentos de proteção individual durante a pandemia da COVID-19.</p>

            <img className="img-fluid mb-5" src={image51} alt="Algoritmo"/>
        </div>
    )
}