import {
  BackButton,
  Background,
  Header
} from './components'

import {
  BrowserRouter as Router, 
  Route, 
  Switch
} from 'react-router-dom'

import {
  AlgoritmosPage,
  EquipamentosPage,
  FolhetosPage,
  HomePage, InitialPage, OrientacoesPage, PandemiaPage, RecomendacoesPage, ReferenciasPage
} from './pages'

export default function App() {
  return (
    <div className="container-fluid">
      <Background/>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/initial" exact component={InitialPage} />
          <Route path="/pandemia" exact component={PandemiaPage} />
          <Route path="/recomandacao" exact component={RecomendacoesPage} />
          <Route path="/equipamentos" exact component={EquipamentosPage} />
          <Route path="/orientacoes" exact component={OrientacoesPage} />
          <Route path="/referencias" exact component={ReferenciasPage} />
          <Route path="/algoritmos" exact component={AlgoritmosPage} />
          <Route path="/folhetos" exact component={FolhetosPage} />
        </Switch>
        <BackButton/>
      </Router>
    </div>
  );
}
