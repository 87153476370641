import style from './style.module.css'

export default function Button({ children, onClick, fullWidth }){
    let width = ""
    if(fullWidth){
        width = style.fullWidth
    }
    return (
        <button className={style.button + " " + width + " btn"} onClick={onClick}>{children}</button>
    )
}