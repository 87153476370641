import { useHistory } from "react-router-dom";
import { Button } from "../../components";

export default function InitialPage(){
    const history = useHistory()
    return (
        <div className="text-center">
            <h1>Sumário</h1>

            <Button
                fullWidth
                onClick={() => history.push("/pandemia")}
            >Pandemia</Button>
            <Button
                fullWidth
                onClick={() => history.push("/recomandacao")}
            >Recomendação para o atendimento domiciliar na pandemia da COVID-19</Button>
            <Button
                fullWidth
                onClick={() => history.push("/equipamentos")}
            >Uso dos equipamentos de proteção individual pelas equipes de saúde</Button>
            <Button
                fullWidth
                onClick={() => history.push("/orientacoes")}
            >Orientações para diminuir o risco da  COVID-19  em nível domiciliar</Button>
            <Button
                fullWidth
                onClick={() => history.push("/algoritmos")}
            >Algoritmos</Button>
            <Button
                fullWidth
                onClick={() => history.push("/folhetos")}
            >Folhetos</Button>
            <Button
                fullWidth
                onClick={() => history.push("/referencias")}
            >Referências</Button>
        </div>
    )
}