import doctor from '../../assets/doctor.png'
import style from './style.module.css'
import {
    Button
} from '../../components'
import { useHistory } from 'react-router-dom'

import MPCAS_Univas from '../../assets/MPCAS_Univas.png'

export default function HomePage(){
    const history = useHistory()
    return (
        <div className="text-center">
            <img className={style.doctor} src={doctor} alt="Doctor" />

            <div className="mt-3 font-white">
                <p className="text-center">José Ronaldo Alves</p>
                <p className="text-center">Thuanny Fernandes Brito Noguchi</p>
                <p className="text-center">Prof. Dr. Geraldo Magela Salomé</p>
            </div>

            <div>
                <Button 
                    onClick={() => history.push("initial")}
                >
                    Início
                </Button>
            </div>

            <div>
                <img className="img-fluid mb-5" src={MPCAS_Univas} alt="MPCAS" />
            </div>
        </div>
    )
}