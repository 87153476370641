import style from './style.module.css'

import image1 from '../../assets/image1.png'
import image1a from '../../assets/image1a.png'
import image1b from '../../assets/image1b.png'
import image2 from '../../assets/image2.png'

export default function PandemiaPage(){
    return (
        <div className="">
            <h1 className={style.title + " text-center"}>PANDEMIA PELA INFECÇÃO PELO SARS COV 2 (Novo Coronavírus)</h1>

            <p>Este novo coronavírus é o agente causal da doença, denominada COVID-19 em alusão à palavra em inglês: Coronavirus Disease e ao ano do aparecimento inicial desta: 2019.</p>
            <p>Os Coronavírus pertencem a um grupo taxonômico de vírus que causam infecções respiratórias e podem acometer humanos e outros animais. Descobertos inicialmente em aves domésticas na década de 1930, vários Coronavírus causam doença respiratória, gastrintestinal, hepática e neurológica nos animais. Apenas sete coronavírus, sabidamente, causam doença nos humanos, desde sintomas leves de resfriado comum até infecções graves do aparelho respiratório, como Pneumonias e Síndrome Respiratória Aguda Grave “SARS”.</p>

            <img className="img-fluid p-3" src={image1} alt="pandemia" />
            <img className="img-fluid p-3" src={image1a} alt="pandemia" />
            <img className="img-fluid p-3" src={image1b} alt="pandemia" />

            <p>A COVID-19 apareceu pela primeira vez na China, em 2019, na cidade de Wuhan e foi responsável pelo surgimento de uma infecção respiratória, conhecida como COVID-19, que pode variar desde uma simples gripe até complicações muito graves, como pneumonia, colocando a vida dos indivíduos infectados em risco. 1,2, 3</p>
            <p>As autoridades sanitárias chinesas tornaram público o surto e divulgaram as medidas em saúde pública em seu país, incluindo vigilância epidemiológica contínua, com investigações clínicas que conduziram ao fechamento do mercado de Wuhan para desinfecção do ambiente. Em 30 de janeiro de 2020, a Organização Mundial da Saúde (OMS) declarou a COVID-19 como Emergência de Saúde Pública de Interesse Mundial.</p>
            <p>Especificamente no Brasil, em 03 de fevereiro de 2020, o Ministério da Saúde (MS) declarou a Emergência em Saúde Pública de Importância Nacional (ESPIN) e, em seguida, sancionou a lei n° 13.979 de 6/2/2020, que dispõe sobre as medidas para enfrentamento da emergência de importância de caráter nacional e internacional diante do alastramento da covid-19.</p>
            <p>Em 6 de fevereiro, o Ministério da Saúde (MS) informou que havia nove casos suspeitos de COVID-19 em cinco estados diferentes do País, sendo que, destes, nenhum caso foi confirmado. No dia 24 de fevereiro de 2020, o Ministério da Saúde informou que havia quatro casos suspeitos de infecção pelo SARS-CoV-2 e que outros 54 casos suspeitos foram descartados. Em 25 de fevereiro de 2020, o Hospital Israelita Albert Einstein registrou a notificação do primeiro caso. </p>
            <p>No mês de março, reconhecia-se o estado de pandemia. O Ministério da Saúde do Brasil, por meio do então ministro, Luiz Henrique Mandetta, posicionou-se de acordo com as orientações da OMS ao adotar o isolamento social chamado de isolamento horizontal, porém o presidente do Brasil, Jair Bolsonaro, defendeu o isolamento somente para o grupo de risco, chamado de isolamento vertical.</p>
            <p>Em 17 de março de 2020, o Estado de São Paulo confirmou a primeira morte; tratava-se de um homem de 62 anos que estava internado em hospital particular. A prefeitura de São Paulo, em 4 de maio, divulgou dados indicativos de que a maior quantidade de mortes por COVID-19 ou casos suspeitos concentrava-se, em sua maioria, em comunidades, cortiços, conjuntos ou núcleos habitacionais.</p>
            <p>Segundo a OMS, a COVID-19 é transmitida por secreções respiratórias e saliva, sendo indicado ter alguns cuidados para evitar contrair a contaminação e transmiti-la, pois, segundo estudos da organização, cada indivíduo contaminado tem potencial para transmitir a doença para até oito pessoas. Os cuidados recomendados pela OMS são: cobrir a boca ao tossir ou espirrar, lavar as mãos regularmente e evitar tocar o rosto, principalmente na região dos olhos, nariz e boca, não cumprimento com aperto de mão, manter distanciamento social de 1.5 metros, usar máscara e evitar aglomeração, conforme a figura a seguir.</p>

            <img className="img-fluid p-3" src={image2} alt="pandemia" />

            <p>Antes da pandemia da COVID-19, a OMS já recomendava cuidados e técnicas de manipular pacientes contaminados por SARS CoV-2.  Os sintomas da contaminação podem aparecer entre 2 a 14 dias após a exposição ao vírus. Isso se dá, porque a incubação do vírus no corpo sem apresentar sintomas pode levar até quatro dias e afeta vários órgãos do corpo humano.</p>

            <ul>
                <li>Febre <strong>=> </strong>78,4% (73,6%-82,8%)</li>
                <li>Tosse <strong>=> </strong>58,3% (51,5%-64,9%)</li>
                <li>Anosmia <strong>=> </strong>52,7% (29,6%-75,2%)</li>
                <li>Disgeusia <strong>=> </strong>43,9% (20,4%-68,9%)</li>
                <li>Fadiga <strong>=> </strong>34% (27,7%-40,5%)</li>
                <li>Expectoração <strong>=> </strong>23,7% (18,5%-29,4%)</li>
                <li>Anorexia <strong>=> </strong>22,9% (14,3%-32,6%)</li>
                <li>Pressão/ dor torácica<strong>=> </strong>	22,9% (16,3%-30,4%)</li>
                <li>Dispneia <strong>=> </strong>33,9% (24,2%-44,3%)</li>
                <li>Mialgias <strong>=> </strong>33% (26%-40,5%)</li>
                <li>Náuseas/Vômitos <strong>=> </strong>	21% (9%-44%)</li>
                <li>Cefaleia <strong>=> </strong>15,4% (11,6%-19,6%)</li>
                <li>Dor de garganta <strong>=> </strong>	13,1% (7,4%-20,3%)</li>
                <li>Calafrios Tremores<strong>=> </strong>	10,9% (5,8%-17,4%)</li>
                <li>Diarreia <strong>=> </strong>9% (6%-12%)</li>
                <li>Rinorreia <strong>=> </strong>7,3% (4,2%-11,3%)</li>
                <li>Dor abdominal <strong>=> </strong>	3% (2%-5%)</li>
            </ul>

            <p>A Organização Mundial da Saúde, preocupada com a disseminação da doença em nível global, faz algumas orientações com o fim de diminuir a propagação desse vírus, recomendando medidas como a higienização das mãos, antissepsia das mãos com álcool em gel 70% para a população em geral e a utilização dos equipamentos de proteção individual pelos profissionais de saúde, como apresentado na figura 5 reproduzida mais adiante.</p>
            <p>Em estudo experimental, avaliaram a persistência do coronavírus em diferentes superfícies inanimadas. 9 O quadro 2, subsequente, apresenta as superfícies em que o vírus se instala.</p>
            
            <ul>
                <li>Aerossóis/poeira <strong>=></strong>	40 minutos a 2 horas e 30minutos</li>
                <li>Aço inoxidável<strong>=></strong> 48 horas</li>
                <li>Metal <strong>=></strong>  5 dias</li>
                <li>Papelão <strong>=></strong>  4-5 dias</li>
                <li>Vidro <strong>=></strong> 4 dias</li>
                <li>Tubo de silicone <strong>=></strong> 5 dias</li>
                <li>Plástico <strong>=></strong> menos de 5 dias</li>
                <li>Luva de látex <strong>=></strong> menos de 8 horas</li>
                <li>Cobre <strong>=></strong>	4 horas</li>
            </ul>

        </div>
    )
}