import { useHistory, useLocation } from 'react-router-dom'
import style from './style.module.css'

export default function BackButton(){
    const history = useHistory()
    const location = useLocation()
    console.log(location.pathname)
    if(location.pathname !== "/")
        return (
            <div className="mv-4 text-center">
                <button className={style.button + " btn btn-default"} onClick={() => {
                    history.goBack()
                }}>Voltar</button>
            </div>
        )
    else
        return null
}